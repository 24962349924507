import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
const GlobalMarket = () => {
       useEffect(() => {
                AOS.init({ duration: 2000 });
            }, [])
    return (
        <div>
            <div class='d-flex align-items-center justify-content-center' style={{ height: '100vh' }}>

                <div class='d-flex align-items-center justify-content-center  col-11 m-auto rounded-4 bg-dark ' style={{ height: '80%' }}>
                    <div class='col-11 m-auto'>

                        <p class='mfs-7 fwh text-white 'data-aos="zoom-in">Welcome to </p>
                        <p class='mfs-7 fwh org'data-aos="zoom-in">3Sum's Global Market Access</p>

                        {/* <p class='mfs-6 text-white fwl' data-aos="zoom-in-up">Write Your Financial Success Story</p> */}
                        <p class=' tJustify mt-2 text-white col-lg-7 col-11 m-auto ' data-aos="zoom-in-up">your gateway to seamless trading across diverse financial landscapes. Through our strategic partnerships, we offer unparalleled services tailored to your trading needs</p>
                    </div>
                </div>
            </div>
            <div class='col-11 m-auto text-start'>

                <div>
                    <p class='fs-3 fwb mt-5 org'data-aos="fade-up">FxPro Offering</p>
                    <p class='mt-2 tJustify'data-aos="fade-up">Experience a sophisticated trading environment with FxPro, a globally recognized broker since 2006. Key features include:</p>
                     <ul>
                        <li data-aos="fade-up"><b> <span class='fw-semibold'>Diverse Trading Instruments :</span></b> Access a wide array of assets, including forex, commodities, indices, shares, and cryptocurrencies, enabling comprehensive portfolio diversification.</li>
                        <li data-aos="fade-up"><b> <span class='fw-semibold'>Advanced Trading Platforms :</span></b> Utilize industry-leading platforms such as MetaTrader 4, MetaTrader 5, cTrader, and FxPro's proprietary platform, each offering unique features to suit various trading strategies. cite turn0search19</li>
                        <li data-aos="fade-up"><b> <span class='fw-semibold'>Competitive Trading Conditions :</span></b> Benefit from tight spreads, fast execution speeds averaging under 12 milliseconds, and leverage up to 1:500, enhancing your trading efficiency. cite turn0search9</li> 

                     </ul>
                </div>

                <div>
                    <p class='fs-3 fwb mt-5 org'data-aos="fade-up">Quantl Offering</p>
                    <p class='mt-2 tJustify'data-aos="fade-up">Leverage the power of AI-driven trading solutions with Quantl. Our platform provides:</p>
                     <ul>
                        <li data-aos="fade-up"><b> <span class='fw-semibold'>AI-Enabled Portfolio Management :</span></b> Tailor your investment strategies with advanced AI tools designed to optimize performance and align with your financial goals.</li>
                        <li data-aos="fade-up"><b> <span class='fw-semibold'>Automated Trading Strategies :</span></b>  Implement sophisticated algorithms without the need for coding, allowing for efficient and effective trading operations.</li>
                        <li data-aos="fade-up"><b> <span class='fw-semibold'>Comprehensive Market Analysis :</span></b> Access in-depth research and insights powered by AI, facilitating informed decision-making in dynamic markets.</li> 

                     </ul>
                </div>

                <div>
                    <p class='fs-3 fwb mt-5 org'data-aos="fade-up">Sweet Futures Offering</p>
                    <p class='mt-2 tJustify'data-aos="fade-up">Expand your trading horizons with Sweet Futures, a premier commodity futures and options brokerage firm. Services include:</p>
                     <ul>
                        <li  data-aos="fade-up"><b> <span class='fw-semibold'>Dedicated Relationship Management :</span></b> Enjoy personalized support from experienced professionals who understand your unique trading requirements, ensuring a tailored approach to your investment strategies. cite turn0search10</li>
                        <li  data-aos="fade-up"><b> <span class='fw-semibold'>Comprehensive Hedging Solutions :</span></b> Access a range of OTC products, including vanilla options, exotic options, swaps, and forwards, designed to manage risk and enhance your trading strategies. cite turn0search12</li>
                        <li  data-aos="fade-up"><b> <span class='fw-semibold'>Advanced Trading Platforms :</span></b> Utilize state-of-the-art technology for efficient and effective trading, supported by exceptional customer service available 24/7. </li> 

                     </ul>
                </div>
            </div>
            <br />
            <br />
        </div>
    )
}

export default GlobalMarket