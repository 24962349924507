import React, { useEffect, useRef } from "react";
import "./home.css";
import AOS from "aos";
import "aos/dist/aos.css";
import "./HomeFooterTop2.css";

const HomeFooterTop2 = () => {
  const refHome1 = useRef(null);

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  useEffect(() => {
    const capMain = document.querySelector(".capMain");
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          capMain.classList.add("active");
        } else {
          capMain.classList.remove("active");
        }
      },
      { rootMargin: "0px", threshold: 0.5 }
    );

    if (refHome1.current) {
      observer.observe(refHome1.current);
    }
    return () => {
      if (refHome1.current) {
        observer.unobserve(refHome1.current);
      }
    };
  }, [refHome1]);

  return (
    <div
      style={{
        backgroundImage: `url(${require("../../Images/portfolioBg.jpg")})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "80vh",
        overflow: "hidden",
      }}
      ref={refHome1}
    >
      <div style={{ width: "100%" }}>
        <div className="col-lg-8 col-md-9 col-11 m-auto mt-5">
          <p className="fbnh mfs-7 org fweb">Level up your portfolio with us.</p>
        </div>
      </div>
      <div className="capMain col-11 m-auto mt-3">
        <p className="fwsb rounded-5 mfs-3 cap1">Forex</p>
        <p className="fwsb rounded-5 mfs-3 cap2">Indices</p>
        <p className="fwsb rounded-5 mfs-3 cap3">Commodities</p>
        <p className="fwsb rounded-5 mfs-3 cap4">Gold</p>
        <p className="fwsb rounded-5 mfs-3 cap5">Silver</p>
        <p className="fwsb rounded-5 mfs-3 cap6">Stocks</p>
        <p className="fwsb rounded-5 mfs-3 cap7">Equity</p>
        <p className="fwsb rounded-5 mfs-3 cap8">Cryptos</p>
        <p className="fwsb rounded-5 mfs-3 cap9">AI Investing</p>
        <p className="fwsb rounded-5 mfs-3 cap10">Algo Trading</p>
      </div>
    </div>
  );
};

export default HomeFooterTop2;
