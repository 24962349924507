import React, { useEffect, useRef, useState } from 'react'; 
import { FaArrowDownLong } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-scroll';
import '../../App.css';
import './homeAbout2.css';

const HomeAbout2 = () => {
    const stickyRef = useRef(null);
    const stickyParentRef = useRef(null);
    const firstDivRef = useRef(null);
    const secondDivRef = useRef(null);
    const thirdDivRef = useRef(null);
    const [showPopUp, setShowPopUp] = useState(false);

    let sum3UserAccessToken = localStorage.getItem("sum3UserAccessToken");
    let sum3UserRole = localStorage.getItem("sum3UserRole");
    let ticking = false; 
    useEffect(() => {
        const horizontalScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(() => {
                    const sticky = stickyRef.current;
                    const stickyParent = stickyParentRef.current;

                    if (!sticky || !stickyParent) return;

                    const scrollWidth = sticky.scrollWidth;
                    const verticalScrollHeight = stickyParent.getBoundingClientRect().height - sticky.getBoundingClientRect().height;
                    const stickyPosition = sticky.getBoundingClientRect().top;

                    const windowWidth = window.innerWidth;
                    const windowHeight = window.innerHeight;

                    if (stickyPosition > 1) {
                        ticking = false; 
                        return;
                    } else {
                        const scrolled = stickyParent.getBoundingClientRect().top;

                        const adjustedScroll = Math.round((scrollWidth / verticalScrollHeight) * -scrolled * 0.80); // Example adjustment factor
                        console.log(adjustedScroll);

                        document.getElementById("stickyRef").scrollLeft = adjustedScroll;
                    }

                    ticking = false; 
                });
                ticking = true; 
            }
        };

        const debouncedScroll = () => {
            horizontalScroll();
        };

        document.addEventListener("scroll", debouncedScroll);
        return () => {
            document.removeEventListener("scroll", debouncedScroll);
        };
    }, []);

    useEffect(() => {
        const down = document.querySelector(".firstDivDown");
        const up = document.querySelector(".firstDivUp");
        const text = document.querySelector(".firstDivText");
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setTimeout(() => {

                        down.classList.add("active")
                        up.classList.add("active")
                        text.classList.add("active")
                    }, 500)
                } else {
                    down.classList.remove("active");
                    up.classList.remove("active");
                    text.classList.remove("active");
                }
            },
            { rootMargin: "0px", threshold: 0.5 }
        );
        if (firstDivRef.current) {
            observer.observe(firstDivRef.current);
        }
        return () => {
            if (firstDivRef.current) {
                observer.unobserve(firstDivRef.current);
            }
        };
    }, [firstDivRef]);

    useEffect(() => {
        const right = document.querySelector(".secondDivRight");
        const left = document.querySelector(".secondDivLeft");
        const text = document.querySelector(".secondDivText");
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setTimeout(() => {

                        right.classList.add("active")
                        left.classList.add("active")
                        text.classList.add("active")
                    }, 500)
                } else {
                    right.classList.remove("active");
                    left.classList.remove("active");
                    text.classList.remove("active");
                }
            },
            { rootMargin: "0px", threshold: 0.5 }
        );
        if (secondDivRef.current) {
            observer.observe(secondDivRef.current);
        }
        return () => {
            if (secondDivRef.current) {
                observer.unobserve(secondDivRef.current);
            }
        };
    }, [secondDivRef]);

    useEffect(() => {
        const right = document.querySelector(".thirdDivRight");
        const left = document.querySelector(".thirdDivLeft");
        const text = document.querySelector(".thirdDivText");
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setTimeout(() => {

                        right.classList.add("active")
                        left.classList.add("active")
                        text.classList.add("active")
                    }, 500)
                } else {
                    right.classList.remove("active");
                    left.classList.remove("active");
                    text.classList.remove("active");
                }
            },
            { rootMargin: "0px", threshold: 0.5 }
        );
        if (thirdDivRef.current) {
            observer.observe(thirdDivRef.current);
        }
        return () => {
            if (thirdDivRef.current) {
                observer.unobserve(thirdDivRef.current);
            }
        };
    }, [thirdDivRef]);

    const navigate = useNavigate()

    return (
        <div class='mt-5'>
            {
                showPopUp &&
                <div class='d-flex align-items-center justify-content-center' style={{ position: 'fixed', height: '100vh', overflowY: " auto", width: '100%', top: '0', left: '0', background: "rgba(0,0,0,0.8)", zIndex: "9999", backdropFilter: 'blur(10px)' }}>
                    <div class='col-md-10 col-11 text-white' >

                        <div class='d-flex align-items-center justify-content-end'>
                            <i class="bi bi-x-circle fs-3" onClick={() => setShowPopUp(false)} style={{ cursor: 'pointer' }}></i>
                        </div>
                        <div class='text-start fs-5'>
                            <p class='fs-4'><b>Are You Penny Wise and Pound Foolish? </b></p>
                            <p class='mt-2 tJustify'>Would you rather save a few dollars by cutting corners, or invest in a trading experience that truly supports your growth and success? </p>
                            <p class='mt-2 tJustify'><b>Value Real Services—</b>like <b> free live trading signals, expert copy trading, mindset coaching, one-on-one consultations, </b>and <b>learning newtrading techniques.</b> </p>
                            <p class='mt-2 tJustify'>If small savings of 1/2 pips in spread is more important to you than these invaluable services, then perhaps another platform is for you. But let’s be real—those minor savings usually disappear easily in market movements and slippage that go unnoticed to the naked eye. (if you didn’t understand what that meant, Welcome to 3SUM!)</p>
                            <p class='mt-2 tJustify'> At 3Sum, we believe in offering more than just superficial savings. We provide the tools and support that make a real difference in your trading
                                journey as a package. Many providers entice you with promises of saving few dollars per trade with thinner spreads, no holding costs etc— this is
                                great if you’re trading millions and perhaps billions every month. But for most new traders, those days are far off, and the truth is, many never reach
                                that level because the cost of trade off is the right platform, the proper understanding of trading basics, and the tools to effectively leverage trading
                                technology.</p>

                        </div>
                    </div>

                </div>
            }

            <div className="sticky-parent" ref={stickyParentRef}>
                <div className="sticky" id='stickyRef' ref={stickyRef}>
                    <div className="horizontal">
                        <div className="dim" style={{ background: '#ffffff' }}>
                            <div className='firstDiv' ref={firstDivRef}>
                                <div className='firstDivDown'   >
                                    <img src={require('../../Images/downArow.png')} alt="" />
                                </div>
                                <div className='firstDivUp' >
                                    <img src={require('../../Images/upArow.png')} alt="" />
                                </div>
                                <div className='firstDivText' >
                                    <p class='mfs-7 text-nowrap fw-semibold'>   Lets do markets, together!</p>
                                </div>
                                <Link
                                    activeClass="active"
                                    to={"home7"}
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                >
                                    <button
                                        class='homeDownBtn bg-dark' >
                                        <p><FaArrowDownLong class='fw-bold' /></p>
                                    </button>
                                </Link>
                                <div class='col-lg-9 col-10' style={{ zIndex: '1' }}>
                                    <p class='mfs-8 fweb text-white'>WHY 3SUM?</p>
                                    <p class='tJustify mfs-4 fwsb'> Most trading Platforms cater to Advanced Traders, overwhelming an average investor looking to enter capital markets.
                                        <br /> That’s where 3Sum comes in.
                                        <br /><b class='text-dark'>A platform for the ones, Who are NOT looking to become full-time traders. </b> An environment where you can start your investment journey
                                        confidently</p>
                                </div>
                            </div>
                        </div>
                        <div className="dim" style={{ background: '#402E32' }}>

                            <div className='thirdDiv ' ref={thirdDivRef}>
                                <div className='thirdDivRight'  >
                                    <img src={require('../../Images/right.png')} alt="" />
                                </div>
                                <div className='thirdDivLeft' >
                                    <img src={require('../../Images/left.png')} alt="" />
                                </div>
                                <div className='thirdDivText' >
                                    <p class='mfs-7 text-nowrap fw-semibold'> HOW TO PARTICIPATE</p>
                                </div>

                                <div class='col-lg-11 row gap-lg-0  gap-2  justify-content-center' style={{ zIndex: '1' }}>
                                    <div class='col-lg-4 my-lg-1'>
                                        <div class='col-11 rounded-4 text-start m-auto ' style={{ border: '1px solid #000' }}>
                                            <div class='col-11 m-auto my-3'>
                                                <p class='htp1 fweb'>01</p>
                                                {/* <p class='htp2 fweb'>SIGN-UP & GAMIFIED ASSESSMENT</p>
                                                <p class='htp3 fwel '>create your account on 3Sum.</p> */}
                                                <p class='htp2 fweb'>Sign Up</p>
                                                <p class='htp3 fwel '>Establish your skill level.</p>
                                                {(!sum3UserAccessToken && !sum3UserRole) &&
                                                    <button class='sumBtn mfs-4 rounded-5 mt-3' onClick={() => navigate('/log-in')}>Sign up</button>
                                                }
                                                {/* <button class='sumBtn mfs-3 rounded-5 mt-2'  onClick={()=>navigate('/log-in')}>Sign up</button> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div class='col-lg-4 my-lg-5'>
                                        <div class='col-11 rounded-4 text-start m-auto ' style={{ border: '1px solid #000' }}>
                                            <div class='col-11 m-auto my-3'>
                                                <p class='htp1 fweb'>02</p>
                                                <p class='htp2 fweb'> Enter Your Customized Ecosystem</p>
                                                <p class='htp3 fwel'> Access a customized package of services specially picked for you</p>
                                                {/* <button class='sumBtn mfs-3 rounded-5 mt-2'  onClick={()=>navigate('/log-in')}>Sign up</button> */}
                                                {(!sum3UserAccessToken && !sum3UserRole) &&
                                                    <button class='sumBtn mfs-4 rounded-5 mt-3' onClick={() => navigate('/log-in')}>Sign up</button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div class='col-lg-4 my-lg-0'>
                                        <div class='col-11 rounded-4 text-start m-auto ' style={{ border: '1px solid #000', zIndex: '1' }}>
                                            <div class='col-11 m-auto my-3'>
                                                <p class='htp1 fweb'>03</p>
                                                <p class='htp2 fweb'> Level Up</p>
                                                <p class='htp3 fwel'> Aactivate services with our partners and level up your portfolio</p>
                                                {/* <button class='sumBtn mfs-3 rounded-5 mt-2'  onClick={()=>navigate('/log-in')}>Sign up</button> */}
                                                {(!sum3UserAccessToken && !sum3UserRole) &&
                                                    <button class='sumBtn mfs-4 rounded-5 mt-3' onClick={() => navigate('/log-in')}>Sign up</button>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dim" style={{ background: '#FFF5F0' }}>

                            <div className='secondDiv' ref={secondDivRef}>

                                <div className='secondDivRight'  >
                                    <img src={require('../../Images/right.png')} alt="" />
                                </div>
                                <div className='secondDivLeft' >
                                    <img src={require('../../Images/left.png')} alt="" />
                                </div>
                                <div className='secondDivText' >
                                    <p class='mfs-7 text-nowrap  fw-semibold'>   Lets do markets, together!</p>
                                </div>

                                <div class='col-lg-9 col-10' style={{ zIndex: '1' }}>
                                    <p class='mfs-8 fweb text-white'>WHAT IS 3SUM?</p>
                                    <p class='tJustify mfs-4 fwsb'>3Sum is a revolutionary platform designed to make trading and investing accessible to everyone, especially beginners. Unlike traditional platforms that cater to advanced traders with complex tools, 3Sum simplifies the experience, offering all the essential resources you need to start your trading journey in one place. We've partnered with some of the most reputable, regulated platforms worldwide to create a secure, user- friendly and a customized environment that adapts to your level of experience. With 3Sum, you don't need to be an expert to make smart investments. </p>
                                </div>

                            </div>


                        </div>
                        <div className="dim">
                            <div className='forthDiv'>
                                <div class='col-lg-9 col-11'>
                                    <p class='mfs-8 fwt  ' style={{ color: "#EDEDED" }}>ARE YOU <br /><span class='fweb ctaText' style={{ color: '#FF823C' }}>PENNY WISE</span> <br />  AND <br /> <span class='fweb ctaText' style={{ color: '#FF823C' }}>POUND FOOLISH? </span></p>
                                    <button class='sumBtn mfs-4 my-4  rounded-5' onClick={() => setShowPopUp(true)}>Dive In</button>
                                    {/* {(!sum3UserAccessToken && !sum3UserRole) &&
                                        <button class='sumBtn mfs-4 rounded-5 mt-3' onClick={() => navigate('/log-in')}>Sign up</button>
                                    } */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default HomeAbout2;
