import React, { useEffect, useState } from 'react'
import { Country, State, City } from "country-state-city";
import ScrollToTop from '../../ScrollToTop';
import './login.css'
import { API } from '../url';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loader from '../Loader';

import {encryptData} from '../../config'


const LoginForm = ({ screen }) => {

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const [selectedCity, setSelectedCity] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [email, setEmail] = useState("")
    const [otp, setOtp] = useState()
    const [name, setName] = useState("")
    const [citys, setCitys] = useState("")
    const [number, setNumber] = useState("")
    const [loading, setLoading] = useState(false)

    const [showFild, setShowFild] = useState("email")

    const [countryCode, setCountryCode] = useState()

    const navigate = useNavigate()

    useEffect(() => {
        const countryList = Country.getAllCountries();
        setCountries(countryList);
    }, []);

    const handleCountryChange = (e) => {
        const selectedOption = e.target.value.split(",");
        const country = selectedOption[0];
        const countryCode = selectedOption[1];
        setSelectedCountry(country);
        setCountryCode(countryCode)
        const stateList = State.getStatesOfCountry(country);
        setStates(stateList);
        setSelectedState("");
        setCities([]);
    };

    const enterEmail = async (el) => {
        setLoading(true)
        try {
            const response = await fetch(`${API}/users/email`, {
                method: 'POST',
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({data : encryptData({ email: el })}) // Fixed the 'body' casing and converted email to JSON format
            });

            const data = await response.json();
            if (!response.ok) {
                throw new Error(data?.message);
            }
            if (data?.isSuccess) {
                Swal.fire({
                    text: data?.message,
                    icon: "success"
                });
                setLoading(false)
                setShowFild("otp");
            } else {
                Swal.fire({
                    text: data?.message,
                    icon: "error"
                });
                setLoading(false) 
            }
        } catch (error) {
            // console.error('Error:', error);
            Swal.fire({
                text: error,
                icon: "error"
            });
            setLoading(false)

        }
    };




    const finchatToken = (el) => {
        const headers = {
            'Accept': 'text/plain',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer 1718b7dbeace4ea2b1145e4b0641e472'
        }
        const payload = { uid: el, userTier: "3" }
        axios.post('https://api.finchat.io/auth/generate-token', { payload: payload }, { headers })
            .then((res) => {
                localStorage.setItem("sum3UserAccessFinChatToken", res.data);
            })
            .catch(function (error) {
                // console.log(error);
            })
    };

    const handleCallCheckMail = (el) => {
        if (el?.length > 0) {
            setOtp('')
            enterEmail(el);
            finchatToken(el)
        } else {
            Swal.fire({
                text: "Please enter your email",
                icon: "error"
            });
        }
    };


    const enterOTP = async () => {
        try {
            const response = await fetch(`${API}/users/otp-verify`, {
                method: 'POST',
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({data : encryptData({email: email, otp: otp })}) // Fixed the 'body' casing and converted email to JSON format
            });

            if (!response.ok) {
                throw new Error('Failed to send OTP');
            }
            const data = await response.json();
            if (data?.isSuccess) {
                Swal.fire({
                    text: data?.message,
                    icon: "success"
                });
                if (data?.payload?.isUserExisting) {
                    if (data?.payload?.testPending) {
                        localStorage.setItem("sum3UserId", data?.payload?.userId);
                        screen("second")
                    } else {
                        localStorage.setItem("sum3UserAccessToken", data?.payload?.accessToken);
                        localStorage.setItem("sum3UserRole", data?.payload?.role);
                        navigate(`/${data?.payload?.role}-invest`)
                    }
                } else {
                    setShowFild("details");
                }


            } else {
                Swal.fire({
                    text: data?.message,
                    icon: "error"
                });
            }
        } catch (error) {
            // console.error('Error:', error);
            Swal.fire({
                text: error,
                icon: "error"
            });
        }
    };

    const handleCallCheckOTP = () => {
        if (otp?.length > 0) {
            enterOTP();
        } else {
            Swal.fire({
                text: "Please enter your OTP",
                icon: "error"
            });
        }
    };


    const enterUserDetails = async () => {
        try {
            const response = await fetch(`${API}/users`, {
                method: 'POST',
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({data : encryptData({ fullname: name, email: email, country: selectedCountry, city: citys, mobile: `${countryCode || ''}${number}`})}) // Fixed the 'body' casing and converted email to JSON format
            });

            if (!response.ok) {
                throw new Error('Failed to send your Details');
            }
            const data = await response.json();
            if (data?.isSuccess) {
                localStorage.setItem("sum3UserId", data?.payload?.userId);
                Swal.fire({
                    text: data?.message,
                    icon: "success"
                });
                screen("second");
            } else {
                Swal.fire({
                    text: data?.message,
                    icon: "error"
                });
            }
        } catch (error) {
            // console.error('Error:', error);
            Swal.fire({
                text: error,
                icon: "error"
            });
        }
    };


    const handleCallUserDetails = () => {
        if (name != undefined && name != '' &&
            email != undefined && email != '' &&
            selectedCountry != undefined && selectedCountry != '' &&
            citys != undefined && citys != '' &&
            number != undefined && number != ''
        ) {
            enterUserDetails();
        } else {
            Swal.fire({
                text: "Please enter all filds",
                icon: "error"
            });
        }
    };









    return (
        <div class='loginBg'>
            <ScrollToTop />
            {loading &&
                <Loader />
            }


            <div class='col-lg-8 col-11'>
                <p class='display-4 fwsb org ' >Let's Do Markets Together!</p>
                <p class='fwt col-10 m-auto mt-2'>Experience a world-class trading environment curated just for you. We’ve partnered with internationally.</p>

                {showFild === "email" &&
                    <div class='rounded-5 bg-white mt-4' style={{ boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 15px' }}>
                        <br />
                        <p class='fs-1 fwsb org'>Enter Email</p>
                        <p class='fwt col-11 m-auto'>We will send you an One Time Passcode via this email address</p>
                        <div class="col-lg-6 col-11 m-auto  form-floating mt-3  p-0 border-danger">
                            <input type="email" class="form-control " id="gmail" placeholder="sum@gmail.com" value={email} onChange={(e) => setEmail(e.target.value)} style={{ border: '0.5px solid #FF823C' }} />
                            <label for="gmail">Email address</label>
                        </div>
                        <div class='mt-4'>
                            <button class='sumBtn mfs-4   rounded-5' onClick={() => handleCallCheckMail(email)} >Verify Email</button>
                        </div>
                        <br />
                    </div>
                }

                {
                    showFild === "otp" &&
                    <div class='rounded-5 bg-white mt-4' style={{ boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 15px' }}>
                        <br />
                        <p class='fs-1 fwsb org'>Enter OTP</p>
                        <p class='fwt col-11 m-auto'> We sent you a secret participation OTP at <span class='fwsb'>{email}</span>  </p>
                        <div class="col-lg-6 col-11 m-auto  form-floating mt-3  p-0 border-danger">
                            <input type="email" class="form-control " id="otp" placeholder="OTP" value={otp} onChange={(e) => setOtp(e.target.value)} style={{ border: '0.5px solid #FF823C' }} />
                            <label for="otp">OTP</label>
                        </div>
                        <p class='m-auto fw-bold my-2'  >Didn’t get it? <span class='text-primary' style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => handleCallCheckMail(email)}>Resend Code</span> , <span class='text-danger' style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => setShowFild("email")}>Change Email ?</span></p>
                        <div class='mt-3'>
                            <button class='sumBtn mfs-4   rounded-5' onClick={() => handleCallCheckOTP()} >Verify OTP</button>
                        </div>
                        <br />
                    </div>
                }

                {
                    showFild === "details" &&
                    <div class='rounded-5 bg-white mt-4' style={{ boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 15px' }}>
                        <br />
                        <p class='fs-1 fwsb org'>Enter Your Details</p>
                        <div class='row col-11 m-auto align-items-center justify-content-lg-around  justify-content-center'>
                            <div class=" col-lg-5 col-11 form-floating mt-4  p-0">
                                <input type="text" class="form-control" id="firstName" placeholder="First Name" value={name} onChange={(e) => setName(e.target.value)} />
                                <label for="firstName">Full Name</label>
                            </div>

                            <div class="  col-lg-5 col-11 form-floating mt-4  p-0">
                                <select
                                    id="country"
                                    name="country"
                                    className="form-control"
                                    value={`${selectedCountry},${countryCode || ''}`}
                                    onChange={handleCountryChange}
                                >
                                    <option value="">Select Country</option>
                                    {countries.map((country) => (
                                        <option key={country.isoCode} value={`${country.name},${country?.phonecode}`} >
                                            {country.name}
                                        </option>
                                    ))}
                                </select>
                                <label for="country">Country</label>
                            </div>
                            <div class="col-lg-5 col-11 form-floating mt-4  p-0">
                                <input type="text" class="form-control" id="city" placeholder="City" value={citys} onChange={(e) => setCitys(e.target.value)} />
                                <label for="city">City</label>
                            </div>

                            <div class=" col-lg-5 col-11 form-floating mt-4  p-0">

                                <input type="tel" class="form-control" id="number" maxLength={10 + (countryCode?.length || 0)} placeholder="Phone/Mobile Number" value={`${countryCode || ''}${number}`} onChange={(e) => {
                                    // Remove country code prefix and only keep the number
                                    const inputNumber = e.target.value.replace(countryCode || '', '').trim();
                                    setNumber(inputNumber);
                                }} />
                                <label for="number">Phone/Mobile Number</label>
                            </div>
                        </div>

                        <div class='mt-4'>
                            <button class='sumBtn mfs-4   rounded-5' onClick={() => handleCallUserDetails()} >Submit</button>
                        </div>
                        <br />
                    </div>
                }

            </div>

        </div>
    )
}

export default LoginForm


