import CryptoJS from 'crypto-js'; 
import md5 from 'md5-hash'
import sha256 from 'sha256'

const now = new Date();
const UTCDate = String(now.getUTCDate()).padStart(2, '0');
const UTCMonth = String(now.getUTCMonth() + 1).padStart(2, '0');
const UTCYear = now.getUTCFullYear();
const universalDate = `${UTCYear}-${UTCMonth}-${UTCDate}`;
const secretKeyString = `cms.io/${universalDate}`;
const md5Date = md5(secretKeyString)
const cryptToken = (sha256(md5Date))  

export const encryptData = (data) => {
    if (!cryptToken) {
        console.error('Secret key is not available');
        return null;
    }
    const jsonData = JSON.stringify(data);
    const ciphertext = CryptoJS.AES.encrypt(jsonData, cryptToken).toString();
    return ciphertext;
};
